<template>
  <div>
    <!--begin::Dashboard-->

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {ORDER_LIST} from "@/core/services/store/order.module";

export default {
  name: "dashboard",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Home", route: "dashboard" }
    ]);
    this.$store.dispatch(ORDER_LIST);
  }
};
</script>
